@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

* { font-family: 'Spoqa Han Sans Neo', 'sans-serif'; }

body {
    background-color: #f5f5f5;
}

.logo {
    background-image: url(../assets/logo/gradiant@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 64px;
}

.ant-steps-item-description {
    min-width: 190px;
    max-width: 80% !important;
}

img.stamp {
    width: 200px;
    height: 200px;
    border: 1px solid #d9d9d9;

    &.br {
        background-image: url(../assets/stamp-bg.png);
    }
}

.attachment {
    > img, > .empty {
        width: 200px;
        height: 200px;
        border: 1px solid #d9d9d9;
    }

    > .empty {
        display: block;
        background-image: url(../assets/stamp-bg.png);
    }

    > p {
        width: 200px;
        white-space: nowrap;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
}